@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: normal;
}
