.fieldset {
  border: 1px solid lightgray;
  padding: 10px;
}

.legend {
  width: initial;
  font-size: 1.2em;
  margin: 0 0 0 10px ;
  padding: 0 5px;
}
